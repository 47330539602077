<script>
import {FilterMatchMode} from 'primevue/api';

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkDialog from "@components/general/AkDialog";
import AkDropdown from "@components/input/AkDropdown";
import AkCheckbox from "@components/input/AkCheckbox";
import AkDateTime from "@components/input/AkDateTime";
import Tag from 'primevue/tag';
import AkButton from "@components/input/AkButton.vue";
import AkInputText from "@components/input/AkInputText.vue";
import AkCalendar from "@components/input/AkCalendar.vue";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import dateFormatter from "@mixins/dateFormatter"
import validationStatusConst from "@mixins/const/validationStatusConst";
import roleMixin from "@mixins/roleMixin";
import mobileMixin from "@mixins/mobileMixin";

/* SERVICES */
import forecastSheetService from "@services/forecastSheetService";
import rolePermissionService from "@services/rolePermissionService";
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";

export default {
  components: {
    AkButton, AkCalendar, AkInputText, AkFormList, AkDropdown, AkCheckbox, AkDateTime, AkDialog, Tag},
  mixins: [randomRef, dateFormatter, validationStatusConst, roleMixin, mobileMixin],
  metaInfo() {
    return {
      title: "forecast_sheet.list",
    }
  },
  data() {
    return {
      date: new Date(),
      perDay: false,
      showOnlyMine: false,
      author: "",
      agerIdSelected: null,
      uerIdSelected: null,
      ceiIdSelected: null,
      list: [],
      typeList: [],
      current: {},
      currentYear: null,
      loading: false,
      toShow: 'all',
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      selectedRows: new Set(),
      selectAll: false,
      userParams: roleMixin.userParams,
      reloadListTimeout: null,
      ceiList: [],
      uerList: [],
      agerList: [],
      startDate: null,
      endDate: null,
      noFiche: "",
      status: null,
    }
  },
  mounted() {
    this.loading = true;
    this.getRef().showTotalLoader();

    this.typeList.push({label: this.$t('data_show_type.all'), value: 'all'});
    this.typeList.push({label: this.$t('data_show_type.redacting'), value: 'redacting'});
    this.typeList.push({label: this.$t('data_show_type.archived'), value: 'archived'});
    this.typeList.push({label: this.$t('data_show_type.validated'), value: 'validated'});

    this.getStoredFilterValues();

    let forecastSheetFilter = {};

    if (this.canOnlyViewArchivedForecasts()) {
      forecastSheetFilter.showArchived = true;
    } else {
      switch (this.toShow) {
        case 'redacting':
          forecastSheetFilter.showRedacting = true;
          break;
        case 'archived':
          forecastSheetFilter.showArchived = true;
          break;
        case 'validated':
          forecastSheetFilter.showValidated = true;
          break;
      }
    }

    forecastSheetFilter.onlyMine = this.showOnlyMine;
    forecastSheetFilter.perDay = this.perDay ? this.date : null;
    forecastSheetFilter.author = this.author;
    forecastSheetFilter.agerId = this.agerIdSelected;
    forecastSheetFilter.uerId = this.uerIdSelected;
    forecastSheetFilter.ceiId = this.ceiIdSelected;
    forecastSheetFilter.startDate = this.startDate;
    forecastSheetFilter.endDate = this.endDate;
    forecastSheetFilter.noFiche = this.noFiche;
    forecastSheetFilter.status = this.status;

    let p1 = forecastSheetService.findAll(forecastSheetFilter);
    p1.then(data => {
      this.list = data
      this.list.forEach(item => {
        item.agentFullName = item.creatorFirstName + ' ' + item.creatorLastName;
        item.statusLabel = this.displayForValidationStatusConst(item.status);
      })
    });

    let p2 = forecastSheetService.findCurrentYear();
    p2.then(data => this.currentYear = data);

    let p3 = rolePermissionService.authorizeAccessByPermissions([
      this.permissionConst.forecast.view,
      this.permissionConst.forecast.viewOther,
      this.permissionConst.forecast.viewValidated,
      this.permissionConst.forecast.viewValidatedOther,
      this.permissionConst.forecast.viewArchived,
      this.permissionConst.forecast.viewArchivedOther
    ], 'or');

    Promise.all([p1, p2, p3]).then(() => {
      this.reloadAgerUerCeiLists().then(() => {
        this.loading = false;
        this.getRef().hideLoader();
      });
    });
  },
  methods: {
    reloadList() {
      if (this.reloadListTimeout) {
        clearTimeout(this.reloadListTimeout);
      }

      this.reloadListTimeout = setTimeout(() => {
        this.loading = true;
        this.getStoredFilterValues();

        let forecastSheetFilter = {};

        if (this.canOnlyViewArchivedForecasts()) {
          forecastSheetFilter.showArchived = true;
        } else {
          switch (this.toShow) {
            case 'redacting':
              forecastSheetFilter.showRedacting = true;
              break;
            case 'archived':
              forecastSheetFilter.showArchived = true;
              break;
            case 'validated':
              forecastSheetFilter.showValidated = true;
              break;
          }
        }

        forecastSheetFilter.perDay = this.perDay ? this.date : null;
        forecastSheetFilter.onlyMine = this.showOnlyMine;
        forecastSheetFilter.author = this.author;
        forecastSheetFilter.agerId = this.agerIdSelected;
        forecastSheetFilter.uerId = this.uerIdSelected;
        forecastSheetFilter.ceiId = this.ceiIdSelected;
        forecastSheetFilter.startDate = this.startDate;
        forecastSheetFilter.endDate = this.endDate;
        forecastSheetFilter.noFiche = this.noFiche;
        forecastSheetFilter.status = this.status;

        let p1 = forecastSheetService.findAll(forecastSheetFilter);
        p1.then(data => {
          this.list = data;
          this.list.forEach(item => {
            item.agentFullName = item.creatorFirstName + ' ' + item.creatorLastName;
            item.statusLabel = this.displayForValidationStatusConst(item.status);
          })
        });

        Promise.all([p1]).then(() => {
          this.selectedRows = new Set();
          this.reloadAgerUerCeiLists().then(() => {
            this.loading = false;
            this.getRef().hideLoader();
          });
        });
      }, 750);
    },
    delete() {
      this.getRef().resetMessages();
      forecastSheetService.delete(this.current).then(data => {
        this.list = this.list.filter(val => val.id !== data.id);
        this.getRef().success(this.$t("forecast_sheet.deleted"));
        this.$refs.dialogDelete.hide();
      }).catch(e => {
        this.getRef().error(e.response.data.error);
        this.$refs.dialogDelete.hide();
      });
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
    verificationView() {
      if (this.canOnlyViewArchivedForecasts())
        this.reloadList();
      return true;
    },
    showAll() {
      this.showOnlyMine = false;
      this.userParams.forecast.showOnlyMine = this.showOnlyMine;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    showMine() {
      this.showOnlyMine = true;
      this.userParams.forecast.showOnlyMine = this.showOnlyMine;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    previousDay() {
      this.date = new Date(this.date.getTime() - (3600 * 1000 * 24));
    },
    nextDay() {
      this.date = new Date(this.date.getTime() + (3600 * 1000 * 24));
    },
    downloadSheet(data) {
      data.downloading = true;
      forecastSheetService.download(data)
          .catch(() => this.getRef().error(this.$t("forecast_sheet.download_failed")))
          .finally(() => data.downloading = false);
    },
    onSelectAllChange() {
      this.selectedRows = new Set();
      if (this.selectAll) {
        this.list.forEach(item => {
          if (item.canDelete) {
            item.selected = true;
            this.selectedRows.add(item);
          }
        });
      } else {
        this.list.forEach(item => {
          item.selected = false;
        });
      }
    },
    onSelectedChange(data) {
      if (!data.selected) {
        if (this.selectAll) {
          this.selectAll = false;
        }
        this.selectedRows.delete(data);
      } else {
        this.selectedRows.add(data);
      }
    },
    deleteSelectedRows() {
      if (this.selectedRows.size === 0) {
        this.getRef().error(this.$t("forecast_sheet.no_forecast_sheet_selected"));
        return;
      }
      if (this.selectedRows.size > 50) {
        this.getRef().error(this.$t("forecast_sheet.too_many_forecast_sheets_selected"));
        return;
      }
      forecastSheetService.deleteList(Array.from(this.selectedRows)).then(() => {
        if (this.selectedRows.size > 1)
          this.getRef().success(this.$t("forecast_sheet.forecast_sheets_deleted"));
        else
          this.getRef().success(this.$t("forecast_sheet.deleted"));
        this.reloadList();
        if (this.selectAll) {
          this.selectAll = false;
          this.selectedRows = new Set();
        }
      }).catch(() => {
        if (this.selectedRows.size > 1)
          this.getRef().error(this.$t("forecast_sheet.forecast_sheets_not_deleted"));
        else
          this.getRef().error(this.$t("forecast_sheet.deleted_failed"));
      });
    },
    resetFilter() {
      this.resetForecastUserParams();
      this.getStoredFilterValues();
      this.reloadList();
      this.getRef().success(this.$t("filter_reset_success"));
    },
    resetForecastUserParams() {
      this.$store.dispatch("resetForecastUserParams");
      this.userParams = this.$store.state.userParams;
    },
    getStoredFilterValues() {
      this.filters['global'].value = this.userParams.forecast.searchValue;
      this.toShow = this.userParams.forecast.typeToShow;
      this.perDay = this.userParams.forecast.perDay;
      this.date = new Date(this.userParams.forecast.date);
      this.author = this.userParams.forecast.author;
      this.agerIdSelected = this.userParams.forecast.agerIdSelected;
      this.uerIdSelected = this.userParams.forecast.uerIdSelected;
      this.ceiIdSelected = this.userParams.forecast.ceiIdSelected;
      this.showOnlyMine = this.userParams.forecast.showOnlyMine;
      this.startDate = this.userParams.forecast.startDate ? new Date(this.userParams.forecast.startDate) : null;
      this.endDate = this.userParams.forecast.endDate ? new Date(this.userParams.forecast.endDate) : null;
      this.noFiche = this.userParams.forecast.noFiche;
      this.status = this.userParams.forecast.status;
    },
    reloadAgerUerCeiLists() {
      let p5 = agerService.findAllCurrent().then(data => this.agerList = data);
      let p6 = uerService.findAllCurrent().then(data => {
        if (this.agerIdSelected != null) this.uerList = data.filter(val => val.agerId === this.agerIdSelected);
        else this.uerList = data;
      });
      let p7 = ceiService.findAllCurrent().then(data => {
        if (this.agerIdSelected != null) data = data.filter(val => val.agerId === this.agerIdSelected);
        if (this.uerIdSelected != null) data = data.filter(val => val.uerId === this.uerIdSelected);
        this.ceiList = data;
      });

      return Promise.all([p5, p6, p7]);
    },
    clearDateRange() {
      this.startDate = null;
      this.endDate = null;
      this.userParams.forecast.startDate = null;
      this.userParams.forecast.endDate = null;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
  },
  computed: {
   
    // CAN
   
    canDeleteSelectedRows() {
      for (let item of this.selectedRows) {
        if (!item.canDelete) return false;
      }

      return true;
    },
  },
  watch: {
    toShow() {
      this.userParams.forecast.typeToShow = this.toShow;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    perDay() {
      this.userParams.forecast.perDay = this.perDay;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    date() {
      if (this.loading) return;
      this.userParams.forecast.date = this.date;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    agerIdSelected() {
      if (this.loading) return;
      this.uerIdSelected = null;
      this.ceiIdSelected = null;
      this.userParams.forecast.agerIdSelected = this.agerIdSelected;
      this.userParams.forecast.uerIdSelected = this.uerIdSelected;
      this.userParams.forecast.ceiIdSelected = this.ceiIdSelected;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    uerIdSelected() {
      if (this.loading) return;
      this.ceiIdSelected = null;
      this.userParams.forecast.uerIdSelected = this.uerIdSelected;
      this.userParams.forecast.ceiIdSelected = this.ceiIdSelected;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    ceiIdSelected() {
      if (this.loading) return;
      this.userParams.forecast.ceiIdSelected = this.ceiIdSelected;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    author() {
      if (this.loading) return;
      this.userParams.forecast.author = this.author;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    startDate() {
      if (this.loading) return;
      this.userParams.forecast.startDate = this.startDate;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    endDate() {
      if (this.loading) return;
      this.userParams.forecast.endDate = this.endDate;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    noFiche() {
      if (this.loading) return;
      this.userParams.forecast.noFiche = this.noFiche;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    status() {
      if (this.loading) return;
      this.userParams.forecast.status = this.status;
      this.$store.dispatch("setUserParams", this.userParams);
      this.reloadList();
    },
    filters: {
      handler() {
        this.userParams.forecast.searchValue = this.filters.global.value;
        this.$store.dispatch("setUserParams", this.userParams);
      },
      deep: true
    },
  },
}
</script>

<template v-if=!roleLoading>
  <AkFormList :ref="ref" :title="$t('forecast_sheet.list')">
    <template v-slot:action>
      <div class="action-bar" v-if="!isMobile">
        <span class="p-input-icon-left">
          <i class="pi pi-search"/>
          <InputText v-model="filters.global.value" :placeholder="$t('search_here')"/>
        </span>
        <RouterLink v-if=this.canCreateForecast() :to="{ name: 'forecastCreate' }" class="btn btn-inverse-primary">
          <i class="fe fe fe-plus pr-1"/>{{ $t('create') }}
        </RouterLink>
        <Button
            v-if="canDeleteForecast()"
            v-text="$t('forecast_sheet.delete_selected_forecast_sheets')"
            class="btn btn-inverse-danger"
            @click="deleteSelectedRows()"/>
      </div>
    </template>
    <template v-slot:search>
      <div class="row" v-if="isMobile">
        <div class="col-lg-12">
          <div class="card card-statistics mb-30">
            <div class="card-body action-bar flex-start">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters.global.value" :placeholder="$t('search_here')"/>
              </span>
              <RouterLink v-if=this.canCreateForecast() :to="{ name: 'forecastCreate' }"
                          class="btn btn-inverse-primary">
                <i class="fe fe fe-plus pr-1"/>{{ $t('create') }}
              </RouterLink>
              <Button
                  v-if="canDeleteSelectedRows"
                  v-text="$t('forecast_sheet.delete_selected_forecast_sheets')"
                  class="btn btn-inverse-danger"
                  @click="deleteSelectedRows()"/>
            </div>
          </div>
        </div>
      </div>
    </template>
    
    <template v-slot:list>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="action-card flex-end">
                <div>
                    <button v-if=showOnlyMine @click="showAll()" class="btn btn-inverse-primary">
                    {{ $t('see_all') }}
                    </button>
                    <button v-else @click="showMine()" class="btn btn-inverse-primary">
                    {{ $t('see_mine') }}
                    </button>
                  <button @click="resetFilter()" class="btn btn-inverse-primary">
                  {{ $t('reset_filter') }}
                  </button>
                </div>
              </div>
              <div class="pt-3">
                <div class="filters">
                  <AkDropdown :label="$t('forecast_sheet.status')"
                              v-if=!canOnlyViewArchivedForecasts()
                              v-model=toShow
                              :options=typeList
                              option-value="value"
                              option-label="label"
                              class-name=""/>
                  <AkCheckbox v-model="perDay"
                              :label="$t('data_show_type.per_day')"
                              class-name=""/>
                  <AkDateTime v-model="date"
                              :showTime="false"
                              v-if="perDay"
                              :label="$t('date')"
                              class-name=""/>
                  <div class="form-group" v-if="perDay">
                    <label>&nbsp;</label>
                    <span style="display: flex;">
                      <span class="btn btn-xs btn-inverse-primary flex-center"
                            style="margin: 0 10px 0 5px; height: 40px;" @click="previousDay()">
                        <i class="fe fe-arrow-left"/>
                      </span>
                      <span class="btn btn-xs btn-inverse-primary flex-center" style="height: 40px;" @click="nextDay()">
                        <i class="fe fe-arrow-right"/>
                      </span>
                    </span>
                  </div>
                  <div class="date">
                    <AkCalendar :label="$t('date_range') + ' du'" :max-date="endDate" v-model="startDate"
                                selection-mode="single" class-name="" style="padding-left: 0 !important;"/>
                    <AkCalendar :label="'Au'" v-model="endDate" :min-date="startDate" selection-mode="single"
                                class-name="" style="padding-left: 0 !important;"/>
                    <i v-if="this.startDate || this.endDate" class="pi pi-times"
                       style="padding-top: 15px; cursor: pointer; color: #6c757d;" @click="clearDateRange()"/>
                  </div>
                </div>
                <div class="filters">
                  <AkInputText v-model="noFiche"
                               :label="$t('forecast_sheet.no_fiche')"
                               class-name=""/>
                  <AkInputText v-model="author"
                               :label="$t('author')"
                               class-name=""/>
                  <AkDropdown :options="agerList"
                              v-model="agerIdSelected"
                              :show-clear=true
                              option-label="label"
                              option-value="id"
                              :label="$t('ager_label')"
                              class-name=""/>
                  <AkDropdown :options="uerList"
                              v-model="uerIdSelected"
                              :show-clear=true
                              option-label="label"
                              option-value="id"
                              :label="$t('uer_label')"
                              class-name=""/>
                  <AkDropdown :options="ceiList"
                              v-model="ceiIdSelected"
                              :show-clear=true
                              option-label="label"
                              option-value="id"
                              :label="$t('cei_label')"
                              class-name=""/>
                </div>
              </div>
              <div class="table-responsive">
                <DataTable :always-show-paginator=false :paginator=true :rows=10
                           v-if="verificationView"
                           :loading="loading || !currentUser"
                           :rowsPerPageOptions="[10,20,50]"
                           :value="list"
                           class="table"
                           :globalFilterFields="['id', 'agentFullName', 'agerLabel','uerLabel','ceiLabel', 'statusLabel']"
                           v-model:filters="filters"
                           sortField="date"
                           :sortOrder=-1
                           scrollable
                           scrollHeight="600px"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           removableSort
                           stateStorage="session" stateKey="dt-forecast-list"
                           responsiveLayout="scroll"
                           stripedRows>
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>
                  <Column bodyStyle="min-width: 200px; overflow: visible; text-align: right"
                          headerStyle="min-width: 200px; text-align: right">
                    <template #body="slotProps">
                      <AkButton 
                        v-if="slotProps.data.canDownload"
                        class-name="btn btn-xs btn-inverse-primary mr-1" 
                        :on-click="() => downloadSheet(slotProps.data)" 
                        :loading="slotProps.data.downloading">
                        <i class="fe fe-download"/>
                      </AkButton>
                      <RouterLink
                          :to="{name: 'forecastDetails', params: {id: slotProps.data.id}}"
                          class="btn btn-xs btn-inverse-primary mr-1">
                        <i class="fe fe-eye"/>
                      </RouterLink>
                      <RouterLink v-if="slotProps.data.canUpdate"
                                  :to="{name: 'forecastUpdate', params: {id: slotProps.data.id}}"
                                  class="btn btn-xs btn-inverse-primary mr-1">
                        <i class="fe fe-edit"/>
                      </RouterLink>
                      <span v-if="slotProps.data.canDelete"
                            class="btn btn-xs btn-inverse-danger"
                            @click="openDeleteDialog(slotProps.data)">
                        <i class="fe fe-trash"/>
                      </span>
                    </template>
                  </Column>
                  <template v-if="canDeleteForecast()">
                    <Column headerStyle="width: 3rem">
                      <template #header>
                        <AkCheckbox v-model="selectAll" @change="onSelectAllChange"/>
                      </template>
                      <template #body="slotProps">
                        <AkCheckbox v-if="slotProps.data.canDelete" v-model="slotProps.data.selected" @change="onSelectedChange(slotProps.data)"/>
                      </template>
                    </Column>
                  </template>
                  <Column field="id" :header="$t('forecast_sheet.no_fiche')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.id }}
                    </template>
                  </Column>
                  <Column field="agentFullName" :header="$t('author')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.agentFullName }}
                    </template>
                  </Column>
                  <Column field="year" :header="$t('year')" :sortable="true">
                    <template #body="slotProps">
                      <template v-if=!slotProps.data.year>{{ currentYear }} - {{ currentYear + 1 }}</template>
                      <template v-else>{{ slotProps.data.year }} - {{ slotProps.data.year + 1 }}</template>
                    </template>
                  </Column>
                  <Column field="date" :header="$t('date')" :sortable="true">
                    <template #body="slotProps">
                      {{ formatDate(slotProps.data.date) }}
                    </template>
                  </Column>
                  <Column field="agerLabel" :header="$t('ager_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.agerLabel }}
                    </template>
                  </Column>
                  <Column field="uerLabel" :header="$t('uer_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.uerLabel }}
                    </template>
                  </Column>
                  <Column field="ceiLabel" :header="$t('cei_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.ceiLabel }}
                    </template>
                  </Column>
                  <Column field="status" :header="$t('forecast_sheet.status')" :sortable="true">
                    <template #body="slotProps">
                      <Tag :value="displayForValidationStatusConst(slotProps.data.status)"
                           :severity="getColorForValidationStatusConst(slotProps.data.status)"/>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:extra>
      <AkDialog
          ref="dialogDelete"
          :auto-hide-on-validate="true"
          :cancel-label="$t('no')"
          :validate-label="$t('yes')"
          :title="$t('forecast_sheet.delete_dialog')"
          width="450px"
          @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('forecast_sheet.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>